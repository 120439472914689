import React, { forwardRef, MutableRefObject } from 'react';
import { CheckboxProps } from './Checkbox.props';
import styles from './Checkbox.module.scss';
import cn from 'classnames';
import MinusIcon from '@glise/icons/MinusIcon';
import CheckIcon from '@glise/icons/CheckIcon';

type CheckboxRef = MutableRefObject<HTMLInputElement>;

const Checkbox = forwardRef<CheckboxRef, CheckboxProps>(
  (props: CheckboxProps, ref) => {
    const {
      onChange,
      description,
      className,
      disabled,
      checked,
      label,
      border,
      fullWidth,
      parentCheckbox,
      readOnly,
      checkboxBorder,
      error,
      errorText,
      ...rest
    } = props;
    return (
      <div
        className={cn(
          className,
          styles.CheckboxContainer,
          border && styles.Border,
          disabled && styles.Disabled,
          checked && styles.Checked,
          fullWidth && styles.FullWidth,
        )}
      >
        <label
          className={cn(styles.CheckboxWrapper, border && styles.Bordered)}
        >
          <input
            {...rest}
            ref={ref as any}
            className={cn(
              styles.Checkbox,
              checked && styles.Checked,
              checkboxBorder && styles.CheckboxBorder,
              error && styles.CheckboxError,
            )}
            type={'checkbox'}
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            readOnly={readOnly}
          />
          {checked && (
            <span
              className={cn(
                styles.CheckedIcon,
                label && styles.LabeledCheckedIcon,
                parentCheckbox && styles.ParentCheckedIcon,
                disabled && styles.DisabledCheckedIcon,
              )}
            >
              {parentCheckbox ? <MinusIcon /> : <CheckIcon />}
            </span>
          )}
          {label && (
            <div className={cn(styles.CheckboxText)}>
              <div className={styles.CheckboxLabel}>{label}</div>
              <div className={styles.CheckboxDescription}>{description}</div>
            </div>
          )}
        </label>
        {errorText && error && <div className={styles.Error}>{error}</div>}
      </div>
    );
  },
);

Checkbox.displayName = 'CheckboxComponent';

export default Checkbox;
