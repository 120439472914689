import { FC, useState } from 'react';
import { LayoutProps } from '@glise/bricks/Layout/Layout.props';
import styles from './Layout.module.scss';
import TryForFree from '@glise/bricks/TryForFree/TryForFree';
import Header from '@glise/bricks/Header/Header';
import Footer from '@glise/bricks/Footer/Footer';
import CookieConsent from '@glise/bricks/CookiesConsent/CookiesConsent';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

const Layout: FC<LayoutProps> = ({ children }) => {
  const [showCookiesModal, setShowCookiesModal] = useState<boolean>(false);
  return (
    <>
      <Header />
      <div
        className={styles.LayoutBackground}
        style={{
          backgroundImage: `url(${CLOUDFRONT_URL}backgroundPuzzles.webp)`,
        }}
      >
        <div className={styles.Layout}>{children}</div>
      </div>
      <TryForFree />
      <Footer setShowCookiesModal={setShowCookiesModal} />
      <CookieConsent
        showCookiesModal={showCookiesModal}
        setShowCookiesModal={setShowCookiesModal}
      />
    </>
  );
};

export default Layout;
