import { forwardRef, MutableRefObject } from 'react';
import styles from './FormInput.module.scss';
import Input from '@glise/components/common/Input/Input';
import { FormInputProps } from '@glise/components/common/FormInput/FormInput.props';
import XMarkIcon from '@glise/icons/XMarkIcon';
import SearchIcon from '@glise/icons/SearchIcon';
import cn from 'classnames';
import EyeSolidIcon from '@glise/icons/EyeSolidIcon';
import EyeSlashedIcon from '@glise/icons/EyeSlashedIcon';

type InputRef = MutableRefObject<HTMLInputElement>;

const FormInput = forwardRef<InputRef, FormInputProps>(
  (props: FormInputProps, ref) => {
    let {
      hideIcons,
      passwordInput,
      showPassword,
      handlePassword,
      clearInput,
      type,
      value,
      label,
      startCustomComponent,
      startCustomComponentClassName,
      endCustomComponent,
      endCustomComponentClick,
      endCustomComponentClassName,
      className,
      onClickFormInput,
      filterInput,
      error,
      errorText,
      required,
      breakpoint = 'md',
      formInputClassName,
      ...rest
    } = props;

    const isInputSearch = type === 'search';

    return (
      <div
        className={cn(
          styles.FormInput,
          formInputClassName && formInputClassName,
        )}
      >
        {label && (
          <div className={cn(styles.Label, filterInput && styles.FilterLabel)}>
            {label} {required && <span className={styles.Required}>*</span>}
          </div>
        )}
        <div className={styles.InputWrapper} onClick={onClickFormInput}>
          {isInputSearch && (
            <div className={cn(styles.LeftIcon, styles.LeftSearch)}>
              <SearchIcon />
            </div>
          )}
          {startCustomComponent && (
            <div
              className={cn(
                styles.LeftIcon,
                styles.CloseLeftBorder,
                styles.StartCustomComponent,
                startCustomComponentClassName && startCustomComponentClassName,
              )}
            >
              {startCustomComponent}
            </div>
          )}
          <Input
            ref={ref}
            {...rest}
            type={type}
            fullWidth
            error={error}
            value={value}
            breakpoint={breakpoint}
            // required={required}
            className={cn(
              type === 'search' ? styles.Search : '',
              startCustomComponent ? styles.Search : '',
              type === 'password' && styles.Password,
              className,
            )}
          />
          {endCustomComponent && (
            <div
              className={cn(
                styles.RightIcon,
                endCustomComponentClick && styles.PointerEvents,
              )}
            >
              <div
                className={cn(styles.RightIconWrapper)}
                onClick={endCustomComponentClick}
              >
                {endCustomComponent}
              </div>
            </div>
          )}
          {isInputSearch &&
            typeof value === 'string' &&
            value?.length > 0 &&
            clearInput && (
              <div
                className={cn(
                  styles.RightIcon,
                  styles.Clickable,
                  styles[breakpoint],
                )}
                onClick={(event) => {
                  event.stopPropagation();
                  clearInput && clearInput();
                }}
              >
                <div className={styles.RightIconWrapper}>
                  <XMarkIcon />
                </div>
              </div>
            )}
          {passwordInput && (
            <div
              className={cn(styles.RightIcon, styles.Clickable)}
              onClick={handlePassword}
            >
              {showPassword ? <EyeSolidIcon /> : <EyeSlashedIcon />}
            </div>
          )}
        </div>
        {errorText && error && <div className={styles.Error}>{error}</div>}
      </div>
    );
  },
);

FormInput.displayName = 'InputComponent';

export default FormInput;
