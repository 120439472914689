import { IconButton as Button } from '@mui/material';
import { IconButtonProps } from './IconButton.props';
import styles from './IconButton.module.scss';
import cn from 'classnames';

function IconButton({
  size = 'md',
  children,
  disabled,
  style = 'gray',
  onClick,
  className,
  onMouseOver,
  onMouseOut,
  iconColor = 'gray',
  ariaLabel,
}: IconButtonProps) {
  return (
    <Button
      aria-label={ariaLabel}
      className={cn(
        className,
        styles.IconButton,
        styles[style],
        styles[size],
        styles[iconColor],
        disabled && styles.Disabled,
      )}
      disabled={disabled}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
      disableRipple
    >
      {children}
    </Button>
  );
}

export default IconButton;
