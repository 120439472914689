import React from 'react';
import { Button } from '@mui/material';
import { ToastComponentProps } from './ToastComponent.props';
import styles from './ToastComponent.module.scss';
import cn from 'classnames';
import WarningIcon from '@glise/icons/WarningIcon';
import ErrorIcon from '@glise/icons/ErrorIcon';
import SuccessIcon from '@glise/icons/SuccessIcon';
import InfoIcon from '@glise/icons/InfoIcon';

const ToastComponent = ({
  type,
  message,
  action,
  close,
  label,
  color,
}: ToastComponentProps) => {
  const getIcon = () => {
    switch (type) {
      case 'warning':
        return <WarningIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'success':
        return <SuccessIcon />;
      default:
        return <InfoIcon />;
    }
  };

  return (
    <div className={styles.Toast}>
      <div className={styles.InfoWrapper}>
        <div className={cn(styles.Icon, styles[type], color && styles[color])}>
          {getIcon()}
        </div>
        <div className={styles.MessageWrapper}>
          <h3 className={cn(styles.Label, color && styles[color])}>{label}</h3>
          <h4 className={styles.Message}>{message}</h4>
        </div>
      </div>
      <div className={styles.ButtonWrapper}>
        {action && (
          <Button
            disableRipple
            className={cn(styles.Button, type == 'error' && styles.ButtonError)}
            onClick={action}
            aria-label={'Action'}
          >
            Action
          </Button>
        )}
        {close && (
          <Button
            disableRipple
            className={cn(
              styles.Button,
              action ? styles.Button : styles.OnlyButton,
            )}
            onClick={close}
            aria-label={'Close'}
          >
            Close
          </Button>
        )}
      </div>
    </div>
  );
};

export default ToastComponent;
