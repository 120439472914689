function SearchIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3335 16.0005L12.8689 12.5359M12.8689 12.5359C13.8066 11.5981 14.3334 10.3263 14.3334 9.00019C14.3334 7.67406 13.8066 6.40224 12.8689 5.46452C11.9311 4.5268 10.6593 4 9.3332 4C8.00706 4 6.73525 4.5268 5.79753 5.46452C4.85981 6.40224 4.33301 7.67406 4.33301 9.00019C4.33301 10.3263 4.85981 11.5981 5.79753 12.5359C6.73525 13.4736 8.00706 14.0004 9.3332 14.0004C10.6593 14.0004 11.9311 13.4736 12.8689 12.5359V12.5359Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchIcon;
