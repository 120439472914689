function EyeSlashedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.31669 6.8525C2.53707 7.77265 1.95654 8.84434 1.61169 10C2.68836 13.615 6.03669 16.25 10 16.25C10.8275 16.25 11.6275 16.135 12.3859 15.9208M5.19003 5.19C6.61721 4.24817 8.29009 3.74735 10 3.75C13.9634 3.75 17.3109 6.385 18.3875 9.99833C17.7974 11.9727 16.5308 13.6763 14.81 14.81M5.19003 5.19L2.50003 2.5M5.19003 5.19L8.23169 8.23167M14.81 14.81L17.5 17.5M14.81 14.81L11.7684 11.7683C12.0005 11.5362 12.1847 11.2605 12.3103 10.9572C12.436 10.6539 12.5007 10.3287 12.5007 10.0004C12.5007 9.67208 12.436 9.34697 12.3103 9.04363C12.1847 8.74029 12.0005 8.46467 11.7684 8.2325C11.5362 8.00033 11.2606 7.81617 10.9572 7.69052C10.6539 7.56487 10.3288 7.5002 10.0004 7.5002C9.67211 7.5002 9.34699 7.56487 9.04365 7.69052C8.74031 7.81617 8.46469 8.00033 8.23253 8.2325M11.7675 11.7675L8.23336 8.23333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeSlashedIcon;
