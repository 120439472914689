import FullLogoIcon from '@glise/icons/FullLogoIcon';
import styles from './Header.module.scss';
import Link from 'next/link';
import Button from '@glise/components/common/Button/Button';
import { useEffect, useState } from 'react';
import MobileHeader from './components/MobileHeader/MobileHeader';
import ArrowIcon from '@glise/icons/ArrowIcon';
import Container from '@mui/material/Container';
import useTranslation from 'next-translate/useTranslation';
import LanguageSwitcher from '@glise/components/common/LanguageSwitcher/LanguageSwitcher';

function Header() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { t } = useTranslation('header');

  // Handle resizing for mobile layout
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 1023);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileHeader />
      ) : (
        <div className={styles.Header}>
          <Container>
            <div className={styles.HeaderContainer}>
              <div className={styles.HeaderLinksContainer}>
                <Link
                  href="/"
                  className={styles.Logo}
                  aria-label={'glise logo'}
                >
                  <FullLogoIcon />
                </Link>
                <Link href="/pricing" aria-label={t('nav.pricing')}>
                  {t('nav.pricing')}
                </Link>
                <Link href="/contact" aria-label={t('nav.contact')}>
                  {t('nav.contact')}
                </Link>
              </div>
              <div className={styles.HeaderButtonsContainer}>
                <LanguageSwitcher />
                <Link href={'/contact'} aria-label={t('actions.bookDemo')}>
                  <Button
                    className={styles.LoginButton}
                    endIcon={<ArrowIcon />}
                    aria-label={t('actions.bookDemo')}
                  >
                    {t('actions.bookDemo')}
                  </Button>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default Header;
