import React, { FC } from 'react';
import { CookiesModalProps } from '@glise/bricks/CookiesConsent/components/CookiesModal/CookiesModal.props';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import styles from './CookiesModal.module.scss';
import XMarkIcon from '@glise/icons/XMarkIcon';
import IconButton from '@glise/components/common/IconButton/IconButton';
import Checkbox from '@glise/components/common/Checkbox/Checkbox';
import Button from '@glise/components/common/Button/Button';
import cn from 'classnames';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';

const CookiesModal: FC<CookiesModalProps> = ({
  showCookiesModal,
  setShowCookiesModal,
  consent,
  handleChange,
  handleSaveChanges,
  handleAcceptAll,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('common');

  return (
    <Dialog
      fullScreen={fullScreen}
      open={showCookiesModal}
      onClose={() => setShowCookiesModal(false)}
      fullWidth
      classes={{ paper: cn(styles.CookiesModal) }}
      sx={{
        '.MuiBackdrop-root': {
          background: 'rgba(24, 24, 26, 0.88)',
        },
      }}
    >
      <div className={styles.Title}>
        <h3>{t('cookiesConsent.title')}</h3>
        <IconButton
          aria-label="close"
          onClick={() => setShowCookiesModal(false)}
          size={'sm'}
          style={'transparent-active'}
        >
          <XMarkIcon />
        </IconButton>
      </div>
      <div className={styles.AgreeText}>
        <Trans
          i18nKey="common:cookiesConsent.agreeText"
          components={{ br: <br /> }}
        />
        in our marketing efforts.
        <Link
          href="/privacy-policy"
          aria-label={t('cookiesConsent.privacyLearnMore')}
        >
          {' '}
          {t('cookiesConsent.privacyLearnMore')}
        </Link>
      </div>
      <div className={styles.CheckboxesContainer}>
        <Checkbox
          checked={false}
          disabled
          name="necessary"
          label={t('cookiesConsent.cookies.necessary.label')}
          description={t('cookiesConsent.cookies.necessary.description')}
        />
        <Checkbox
          checked={consent.marketing}
          onChange={handleChange}
          name="marketing"
          label={t('cookiesConsent.cookies.marketing.label')}
          description={t('cookiesConsent.cookies.marketing.description')}
        />
        <Checkbox
          checked={consent.personalization}
          onChange={handleChange}
          name="personalization"
          label={t('cookiesConsent.cookies.personalization.label')}
          description={t('cookiesConsent.cookies.personalization.description')}
        />
        <Checkbox
          checked={consent.analytics}
          onChange={handleChange}
          name="analytics"
          label={t('cookiesConsent.cookies.analytics.label')}
          description={t('cookiesConsent.cookies.analytics.description')}
        />
      </div>
      <div className={styles.ActionButtonsWrapper}>
        <Button
          color={'gray'}
          breakpoint={'lg'}
          onClick={handleSaveChanges}
          aria-label={t('cookiesConsent.save')}
        >
          {t('cookiesConsent.save')}
        </Button>
        <Button
          onClick={handleAcceptAll}
          breakpoint={'lg'}
          aria-label={t('cookiesConsent.acceptCookies')}
        >
          {t('cookiesConsent.acceptCookies')}
        </Button>
      </div>
    </Dialog>
  );
};

export default CookiesModal;
