import ChevronIcon from '@glise/icons/ChevronIcon';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { LanguageSwitcherProps } from '@glise/components/common/LanguageSwitcher/LanguageSwitcher.props';
import EnglandIcon from '@glise/icons/Countries/EnglandIcon';
import SpainIcon from '@glise/icons/Countries/SpainIcon';

const LanguageSwitcher: FC<LanguageSwitcherProps> = () => {
  const { t, lang } = useTranslation('common');
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const handleLangChange = (event: SelectChangeEvent<string>) => {
    const selectedLang = event.target.value as string;
    router.push(router.pathname, router.asPath, { locale: selectedLang });
  };

  return (
    <Select
      value={lang}
      onChange={handleLangChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      variant="standard"
      IconComponent={ChevronIcon}
      SelectDisplayProps={{ 'aria-label': t('language') }}
      sx={{
        color: '#edeef0',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '18px',
        display: 'flex',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '0.5rem',
        borderRadius: '6px',
        border: '1px solid rgba(255, 255, 255, 0.10)',
        backgroundColor: open ? 'rgba(255, 255, 255, 0.10)' : 'transparent',
        paddingRight: '0.5rem',
        transition: '0.25s ease-in-out',
        width: 'fit-content',
        '&:after, &:before': {
          display: 'none',
        },
        '& .MuiSelect-select': {
          paddingRight: '0',
          padding: '0 0 0 0.5rem',
          display: 'flex',
          alignItems: 'center',
          height: '30px',
          gap: '0.5rem',
        },
        '& .MuiInput-input': {
          paddingRight: '0!important',
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            backgroundColor: '#1B1B1F',
            border: '1px solid #2E3035',
            padding: '0.75rem 0',
            borderRadius: '6px',
            '& .MuiList-root': {
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              alignItems: 'center',
              padding: 0,
            },
            '& .MuiMenuItem-root': {
              color: '#EDEEF0',
              padding: '0.25rem 1rem',
              width: '100%',
              display: 'flex',
              gap: '0.5rem',
              alignItems: 'center',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              minHeight: 'fit-content',
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: 'rgba(107, 104, 255, 0.16)!important',
            },
            '& .Mui-selected': {
              borderTop: '1px solid #6B68FF',
              borderBottom: '1px solid #6B68FF',
              backgroundColor: 'rgba(107, 104, 255, 0.16)!important',
            },
            '& .Mui-selected:hover': {
              borderTop: '1px solid #6B68FF',
              borderBottom: '1px solid #6B68FF',
              backgroundColor: 'rgba(107, 104, 255, 0.16)!important',
            },
          },
        },
      }}
    >
      <MenuItem value="en" aria-label={'English'}>
        <EnglandIcon />
        English
      </MenuItem>
      <MenuItem value="es" aria-label={'Español'}>
        <SpainIcon />
        Español
      </MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
