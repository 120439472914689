function SandwichLineIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 6H15" stroke="currentColor" strokeLinecap="round" />
      <path d="M5 10H15" stroke="currentColor" strokeLinecap="round" />
      <path d="M5 14H15" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}

export default SandwichLineIcon;
