module.exports = {
  locales: ['en', 'es'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'header', 'footer', 'metainfo'],
    '/': ['home', 'pricing'],
    '/contact': ['contact'],
    '/pricing': ['pricing'],
    '/404': ['not-found'],
    '/privacy-policy': ['privacy-policy'],
    '/terms-and-conditions': ['terms-of-use'],
  },
  loadLocaleFrom: (lang, ns) =>
    import(`./public/locales/${lang}/${ns}.json`).then((m) => m.default),
};
