import { Button as MuiButton } from '@mui/material';
import { ButtonProps } from './Button.props';
import styles from './Button.module.scss';
import cn from 'classnames';

function Button({
  children,
  startIcon,
  endIcon,
  breakpoint = 'md',
  color = 'blue',
  onClick,
  className,
  disabled,
  type,
}: ButtonProps) {
  const buttonClasses = cn(
    styles.Button,
    styles[breakpoint],
    styles[color],
    className,
  );

  return (
    <MuiButton
      className={buttonClasses}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disableTouchRipple
      disabled={disabled}
      type={type}
    >
      {children}
    </MuiButton>
  );
}

export default Button;
