import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import CookiesPopup from '@glise/bricks/CookiesConsent/components/CookiesPopup/CookiesPopup';
import CookiesModal from '@glise/bricks/CookiesConsent/components/CookiesModal/CookiesModal';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import { useRouter } from 'next/router';

export interface ConsentState {
  marketing: boolean;
  personalization: boolean;
  analytics: boolean;
}

interface CookieConsentProps {
  showCookiesModal: boolean;
  setShowCookiesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Fbq {
  queue?: any[];
  version?: string;
  loaded?: boolean;
  push?: (...args: any[]) => void;
  callMethod?: (...args: any[]) => void;

  (...args: any[]): void;
}

type GtagFunction = (
  command: 'config' | 'event' | 'set' | 'js' | string,
  targetIdOrName: string | Date | { [key: string]: any },
  params?: { [key: string]: any },
) => void;

declare global {
  interface Window {
    GA_INITIALIZED?: boolean;
    GTM_INITIALIZED?: boolean;
    FB_PIXEL_INITIALIZED?: boolean;
    HOTJAR_INITIALIZED?: boolean;
    dataLayer: any[];
    hj: any;
    _hjSettings: any;
    fbq?: Fbq;
    gtag?: GtagFunction;
  }
}

export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID ?? '';
export const PIXEL_ID = process.env.NEXT_PUBLIC_PIXEL_ID ?? '';
export const GTAG_ID = process.env.NEXT_PUBLIC_GTAG_ID ?? '';
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? '';

const CookieConsent: React.FC<CookieConsentProps> = ({
  showCookiesModal,
  setShowCookiesModal,
}) => {
  const router = useRouter();
  const [showCookiesPopup, setShowCookiesPopup] = useState(false);
  const [consent, setConsent] = useState<ConsentState>({
    marketing: false,
    personalization: false,
    analytics: false,
  });

  useEffect(() => {
    const consentCookie = Cookies.get('cookie_consent');
    if (!consentCookie) {
      setShowCookiesPopup(true);
    }
  }, [router]);

  useEffect(() => {
    const consentCookie = Cookies.get('cookie_consent');
    if (!consentCookie) {
      setShowCookiesPopup(true);
    } else {
      const savedConsent: ConsentState = JSON.parse(consentCookie);
      setConsent(savedConsent);
      initializeScripts(savedConsent);
    }
  }, []);

  const handleAcceptAll = () => {
    const allConsent: ConsentState = {
      marketing: true,
      personalization: true,
      analytics: true,
    };
    setConsent(allConsent);
    Cookies.set('cookie_consent', JSON.stringify(allConsent), { expires: 365 });
    initializeScripts(allConsent);
    setShowCookiesPopup(false);
    setShowCookiesModal(false);
  };

  const handleSaveChanges = () => {
    Cookies.set('cookie_consent', JSON.stringify(consent), { expires: 365 });
    initializeScripts(consent);
    setShowCookiesModal(false);
    setShowCookiesPopup(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConsent({
      ...consent,
      [event.target.name]: event.target.checked,
    });
  };

  const initializeScripts = (consent: ConsentState) => {
    if (consent.analytics || consent.marketing || consent.personalization) {
      initializeGTM(consent);
    }

    if (consent.analytics) {
      initializeGA4();
      initializeHotjar();
    } else {
      disableGA4();
      disableHotjar();
    }

    if (consent.marketing) {
      initializeFacebookPixel();
    } else {
      disableFacebookPixel();
    }

    if (consent.personalization) {
      // Initialize personalization scripts
    } else {
      Cookies.remove('NEXT_LOCALE');
    }
  };

  const initializeGTM = (consent: ConsentState) => {
    if (typeof window !== 'undefined') {
      const consentSettings = {
        analytics_storage: consent.analytics ? 'granted' : 'denied',
        ad_storage: consent.marketing ? 'granted' : 'denied',
        functionality_storage: consent.personalization ? 'granted' : 'denied',
      };

      if (!window.GTM_INITIALIZED) {
        TagManager.initialize({
          gtmId: GTM_ID,
          dataLayer: {
            event: 'consent_update',
            consent: consentSettings,
          },
        });
        window.GTM_INITIALIZED = true;
      } else {
        window.dataLayer.push({
          event: 'consent_update',
          consent: consentSettings,
        });
      }
    }
  };

  const initializeGA4 = () => {
    if (typeof window !== 'undefined' && !window.GA_INITIALIZED) {
      ReactGA.initialize(GTAG_ID);
      window.GA_INITIALIZED = true;
      ReactGA.send('pageview');
    }
  };

  const disableGA4 = () => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: 'denied',
      });
    }
  };

  const initializeHotjar = () => {
    if (typeof window !== 'undefined' && !window.HOTJAR_INITIALIZED) {
      hotjar.initialize({ id: parseInt(HOTJAR_ID), sv: 6 }); // Replace 6 with your Hotjar snippet version if different
      window.HOTJAR_INITIALIZED = true;
    }
  };

  const disableHotjar = () => {
    if (typeof window !== 'undefined' && window.hj) {
      window.hj('optOut');
    }
  };

  const initializeFacebookPixel = async () => {
    if (typeof window !== 'undefined' && !window.FB_PIXEL_INITIALIZED) {
      const ReactPixel = (await import('react-facebook-pixel')).default;

      ReactPixel.init(PIXEL_ID);
      ReactPixel.pageView();
      window.FB_PIXEL_INITIALIZED = true;
    } else if (typeof window !== 'undefined' && window.FB_PIXEL_INITIALIZED) {
      const ReactPixel = (await import('react-facebook-pixel')).default;
      ReactPixel.pageView();
    }
  };

  const disableFacebookPixel = async () => {
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('consent', 'revoke');
    }
  };

  return (
    <>
      {/* Bottom Right Modal */}
      {showCookiesPopup && (
        <CookiesPopup
          setShowCookiesModal={setShowCookiesModal}
          setShowPopup={setShowCookiesPopup}
          handleAcceptAll={handleAcceptAll}
        />
      )}

      {/* Settings Modal */}
      <CookiesModal
        showCookiesModal={showCookiesModal}
        setShowCookiesModal={setShowCookiesModal}
        consent={consent}
        handleChange={handleChange}
        handleSaveChanges={handleSaveChanges}
        handleAcceptAll={handleAcceptAll}
      />
    </>
  );
};

export default CookieConsent;
