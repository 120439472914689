import ArrowIcon from '@glise/icons/ArrowIcon';
import styles from './TryForFree.module.scss';
import Button from '@glise/components/common/Button/Button';
import { TryForFreeProps } from '@glise/bricks/TryForFree/TryForFree.props';
import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { CLOUDFRONT_URL } from '@glise/utils/constants';

const TryForFree: FC<TryForFreeProps> = () => {
  const { t } = useTranslation('common');
  return (
    <div
      className={styles.TrialContainer}
      id="start-for-free"
      style={{
        backgroundImage: `url(${CLOUDFRONT_URL}backgroundPuzzlesReversed.webp)`,
      }}
    >
      <div className={styles.TrialTextContainer}>
        <div className={styles.BigText}>{t('tryForFree.headline')}</div>
        <div className={styles.SmallText}>
          <Trans
            i18nKey="common:tryForFree.title"
            components={{ br: <br /> }}
          />
        </div>
        <Button
          className={styles.Button}
          endIcon={<ArrowIcon />}
          color={'white'}
          breakpoint={'xl'}
          aria-label={t('tryForFree.button.label')}
        >
          {t('tryForFree.button.label')}
        </Button>
      </div>
    </div>
  );
};

export default TryForFree;
