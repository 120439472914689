import toast from 'react-hot-toast';
import ToastComponent from '@glise/components/common/Toast/Components/ToastComponent/ToastComponent';
import { ToastProps } from '@glise/components/common/Toast/Toast.props';

const Toast = ({ type, label, message, action }: ToastProps) => {
  return toast(
    (t) => (
      <ToastComponent
        type={type}
        label={label}
        message={message}
        action={action}
        close={() => toast.dismiss(t.id)}
      />
    ),
    { id: message, position: 'top-right' },
  );
};

export default Toast;
