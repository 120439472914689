import React, { forwardRef, MutableRefObject } from 'react';
import styles from './Input.module.scss';
import cn from 'classnames';
import { InputProps } from '@glise/components/common/Input/Input.props';

type InputRef = MutableRefObject<HTMLInputElement>;

const Input = forwardRef<InputRef, InputProps>((props: InputProps, ref) => {
  const {
    className,
    error,
    value,
    fullWidth,
    breakpoint = 'md',
    onKeyDown,
    onFocus,
    ...rest
  } = props;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Prevent the event from bubbling up
    e.nativeEvent.stopImmediatePropagation(); // Immediately stop all other event listeners
    onKeyDown && onKeyDown(e);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation(); // Stop the focus event from propagating
    e.nativeEvent.stopImmediatePropagation(); // Stop other listeners for the focus event
    onFocus && onFocus(e);
  };

  return (
    <input
      ref={ref as any}
      {...rest}
      className={cn(
        styles.Input,
        className,
        error && styles.InputError,
        styles[breakpoint],
        fullWidth && styles.FullWidth,
      )}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      value={value}
    />
  );
});

Input.displayName = 'InputComponent';

export default Input;
