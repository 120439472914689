const EnglandIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4944_13429)">
        <path d="M0 0.5H12V9.5H0V0.5Z" fill="#012169" />
        <path
          d="M1.40625 0.5L5.98125 3.89375L10.5375 0.5H12V1.6625L7.5 5.01875L12 8.35625V9.5H10.5L6 6.14375L1.51875 9.5H0V8.375L4.48125 5.0375L0 1.7V0.5H1.40625Z"
          fill="white"
        />
        <path
          d="M7.95 5.76875L12 8.75V9.5L6.91875 5.76875H7.95ZM4.5 6.14375L4.6125 6.8L1.0125 9.5H0L4.5 6.14375ZM12 0.5V0.55625L7.33125 4.08125L7.36875 3.25625L11.0625 0.5H12ZM0 0.5L4.48125 3.8H3.35625L0 1.2875V0.5Z"
          fill="#C8102E"
        />
        <path
          d="M4.51875 0.5V9.5H7.51875V0.5H4.51875ZM0 3.5V6.5H12V3.5H0Z"
          fill="white"
        />
        <path
          d="M0 4.11875V5.91875H12V4.11875H0ZM5.11875 0.5V9.5H6.91875V0.5H5.11875Z"
          fill="#C8102E"
        />
      </g>
      <defs>
        <clipPath id="clip0_4944_13429">
          <rect y="0.5" width="12" height="9" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EnglandIcon;
