function FullLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="32"
      viewBox="0 0 110 32"
      fill="none"
    >
      <path
        d="M0.000976562 12.8069H10.2417V19.432H0.000976562V12.8069Z"
        fill="white"
      />
      <path
        d="M0.000976562 2.70044H22.5122V9.3255H0.000976562V2.70044Z"
        fill="white"
      />
      <path
        d="M0.000976562 22.8201H22.5122V29.4451H0.000976562V22.8201Z"
        fill="white"
      />
      <path
        d="M32.5118 0.0341914C32.1268 0.0113714 31.737 0 31.3427 0C28.9477 0 26.7238 0.404339 24.6709 1.21309C22.618 2.05287 20.8296 3.23479 19.3055 4.75885C17.7814 6.283 16.5995 8.08701 15.7597 10.1709C14.9199 12.2549 14.5 14.5721 14.5 17.1226C14.5 19.2376 14.8421 21.2127 15.5264 23.0479C16.2418 24.883 17.2372 26.4848 18.5124 27.8534C19.7877 29.2219 21.2962 30.2795 23.038 31.026C24.8109 31.7724 26.7549 32.1457 28.8699 32.1457C29.181 32.1457 29.5698 32.1301 30.0363 32.099C30.5028 32.099 30.985 32.0835 31.4826 32.0524C31.8737 32.0279 32.2168 31.9938 32.5118 31.9502V16.8893H26.8637V25.2375C26.0636 25.2106 25.3171 25.0095 24.6243 24.6341C23.8778 24.2298 23.2246 23.6699 22.6648 22.9545C22.1049 22.2703 21.6539 21.4615 21.3117 20.5285C21.0007 19.6264 20.8452 18.6622 20.8452 17.6358C20.8452 16.1739 21.1095 14.8054 21.6383 13.5302C22.1981 12.2549 22.9446 11.1351 23.8778 10.1709C24.842 9.23783 25.9617 8.49135 27.2369 7.93145C28.5122 7.40272 29.8808 7.13832 31.3427 7.13832C31.7401 7.13832 32.1298 7.15596 32.5118 7.19123V0.0341914Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4437 22.8201C15.4708 22.8963 15.4984 22.9722 15.5266 23.0479C16.242 24.883 17.2373 26.4849 18.5126 27.8535C19.0605 28.4414 19.6515 28.9721 20.2856 29.4452H0V22.8201H15.4437ZM21.8341 2.70044C20.923 3.29825 20.0803 3.98439 19.3057 4.75896C17.9917 6.07308 16.9319 7.59521 16.1265 9.3255H0V2.70044H21.8341ZM0 12.8069H10.2408V19.432H0V12.8069Z"
        fill="#A4B0F5"
        fillOpacity="0.08"
      />
      <path
        d="M51.0584 27.8857C49.448 27.8857 47.9849 27.6205 46.6691 27.0903C45.3533 26.56 44.2142 25.8137 43.2518 24.8514C42.2895 23.8891 41.5432 22.7598 41.013 21.4637C40.5023 20.1675 40.2471 18.7633 40.2471 17.2511C40.2471 15.7389 40.5122 14.3347 41.0424 13.0385C41.5924 11.7423 42.3583 10.6229 43.3402 9.6802C44.3418 8.71789 45.5104 7.97164 46.8458 7.44137C48.1812 6.9111 49.6443 6.646 51.2352 6.646C52.3349 6.646 53.3857 6.79328 54.3872 7.08785C55.3888 7.38242 56.302 7.78506 57.1269 8.29569C57.9517 8.80623 58.6293 9.39545 59.1595 10.0632L56.4199 12.9501C55.654 12.2235 54.8389 11.6638 53.9748 11.2709C53.1107 10.8586 52.1778 10.6523 51.1762 10.6523C50.2532 10.6523 49.3989 10.8193 48.6133 11.1531C47.8474 11.4674 47.1699 11.9289 46.5807 12.5377C46.0112 13.1269 45.5594 13.8241 45.2255 14.6293C44.9113 15.4345 44.7542 16.3084 44.7542 17.2511C44.7542 18.1741 44.9211 19.0382 45.255 19.8434C45.6085 20.6486 46.0799 21.3557 46.6691 21.9645C47.2779 22.5536 47.9849 23.025 48.7901 23.3784C49.5953 23.7123 50.4594 23.8792 51.3824 23.8792C52.0305 23.8792 52.6393 23.7811 53.2089 23.5847C53.798 23.3686 54.3087 23.0839 54.7407 22.7304C55.1925 22.3768 55.5459 21.9645 55.8012 21.4931C56.0565 21.0218 56.1842 20.5112 56.1842 19.9613V19.2543L56.7439 20.0791H50.9111V16.3673H60.3968C60.436 16.5834 60.4655 16.8485 60.4851 17.1627C60.5048 17.4769 60.5146 17.7814 60.5146 18.0759C60.5343 18.3705 60.5441 18.616 60.5441 18.8124C60.5441 20.1478 60.3084 21.3753 59.837 22.4947C59.3657 23.5945 58.698 24.547 57.8338 25.3522C56.9698 26.1574 55.9583 26.7859 54.7996 27.2376C53.6409 27.6696 52.3939 27.8857 51.0584 27.8857Z"
        fill="white"
      />
      <path
        d="M63.9131 27.5912V5.79175H68.0957V27.5912H63.9131Z"
        fill="white"
      />
      <path
        d="M72.6665 27.5913V12.0077H76.8499V27.5913H72.6665ZM74.7582 8.79662C73.9336 8.79662 73.2954 8.60029 72.8437 8.20748C72.3919 7.81467 72.166 7.255 72.166 6.52832C72.166 5.86058 72.3919 5.32056 72.8437 4.9081C73.3148 4.47607 73.9529 4.26001 74.7582 4.26001C75.5441 4.26001 76.1622 4.45642 76.614 4.84923C77.0657 5.24197 77.2916 5.80171 77.2916 6.52832C77.2916 7.2157 77.0657 7.76562 76.614 8.17801C76.1622 8.59047 75.5441 8.79662 74.7582 8.79662Z"
        fill="white"
      />
      <path
        d="M86.5897 27.8858C85.1563 27.8858 83.8698 27.6501 82.7312 27.1788C81.611 26.7075 80.6982 26.0398 79.9912 25.1756L82.6128 22.9368C83.2216 23.5849 83.8892 24.0562 84.6163 24.3508C85.3628 24.6258 86.1186 24.7632 86.8844 24.7632C87.1598 24.7632 87.4151 24.7338 87.6502 24.6748C87.8861 24.5963 88.0927 24.4981 88.2691 24.3802C88.4454 24.2428 88.5738 24.0857 88.652 23.9089C88.7502 23.7322 88.7989 23.5259 88.7989 23.2903C88.7989 22.8583 88.6226 22.5244 88.2691 22.2887C88.112 22.1709 87.8173 22.0432 87.3857 21.9057C86.9726 21.7486 86.4427 21.5817 85.7944 21.4049C84.7927 21.1496 83.9487 20.8551 83.261 20.5212C82.5734 20.1677 82.0141 19.765 81.5816 19.3134C81.2281 18.9402 80.9535 18.5082 80.757 18.0172C80.5807 17.5262 80.4917 16.9861 80.4917 16.397C80.4917 15.4543 80.7671 14.6295 81.3171 13.9224C81.8671 13.2154 82.5935 12.6655 83.497 12.2727C84.4198 11.88 85.4216 11.6836 86.5015 11.6836C87.3068 11.6836 88.0826 11.7818 88.8291 11.9782C89.5949 12.1746 90.302 12.4593 90.9494 12.8325C91.6178 13.186 92.1972 13.6082 92.6876 14.0992L90.4489 16.6032C90.0954 16.2497 89.6932 15.9453 89.2414 15.6899C88.7897 15.415 88.3379 15.199 87.8861 15.0418C87.4344 14.8651 87.0221 14.7767 86.6484 14.7767C86.315 14.7767 86.0103 14.8062 85.7357 14.8651C85.4804 14.924 85.2646 15.0222 85.0874 15.1597C84.911 15.2775 84.7733 15.4248 84.6751 15.6016C84.5962 15.7783 84.5575 15.9748 84.5575 16.1907C84.5575 16.4068 84.6063 16.613 84.7045 16.8093C84.8221 16.9861 84.9799 17.153 85.1756 17.3101C85.3721 17.428 85.6861 17.5655 86.1186 17.7226C86.5703 17.8797 87.1497 18.0565 87.8567 18.2529C88.7989 18.5082 89.5949 18.7929 90.2424 19.1071C90.8906 19.4214 91.4112 19.7847 91.8042 20.1971C92.1577 20.5507 92.403 20.963 92.5406 21.4344C92.6977 21.8861 92.7766 22.377 92.7766 22.9073C92.7766 23.8696 92.5012 24.7338 91.9512 25.4997C91.4213 26.246 90.6849 26.8351 89.7419 27.2672C88.8191 27.6796 87.7686 27.8858 86.5897 27.8858Z"
        fill="white"
      />
      <path
        d="M103.182 27.8858C101.493 27.8858 100.01 27.5422 98.7337 26.8548C97.4767 26.1674 96.495 25.2247 95.788 24.0267C95.1003 22.8288 94.7568 21.4638 94.7568 19.932C94.7568 18.7144 94.9533 17.6048 95.3463 16.6032C95.7385 15.5819 96.2885 14.708 96.9955 13.9814C97.7025 13.2547 98.5372 12.695 99.4995 12.3022C100.482 11.8898 101.552 11.6836 102.711 11.6836C103.771 11.6836 104.743 11.88 105.627 12.2727C106.53 12.6459 107.306 13.186 107.955 13.893C108.622 14.5803 109.133 15.3954 109.486 16.338C109.84 17.2808 110.007 18.3118 109.987 19.4312L109.957 20.7274H97.4372L96.7604 18.135H106.511L106.01 18.6653V18.0467C105.971 17.4967 105.794 17.0156 105.48 16.6032C105.185 16.1907 104.802 15.8667 104.331 15.6311C103.859 15.3757 103.339 15.2481 102.769 15.2481C101.886 15.2481 101.139 15.4248 100.531 15.7783C99.9412 16.1122 99.4895 16.6032 99.1754 17.2513C98.8807 17.8797 98.7337 18.6653 98.7337 19.6079C98.7337 20.531 98.9302 21.346 99.3232 22.053C99.7153 22.7404 100.275 23.2706 101.002 23.6438C101.748 24.0169 102.622 24.2035 103.624 24.2035C104.311 24.2035 104.94 24.0955 105.509 23.8794C106.079 23.6635 106.697 23.2903 107.365 22.76L109.339 25.5586C108.789 26.0496 108.17 26.4718 107.483 26.8253C106.796 27.1788 106.089 27.444 105.362 27.6207C104.635 27.7974 103.909 27.8858 103.182 27.8858Z"
        fill="white"
      />
    </svg>
  );
}

export default FullLogoIcon;
