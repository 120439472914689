import styles from './Footer.module.scss';
import FullLogoIcon from '@glise/icons/FullLogoIcon';
import Link from 'next/link';
import Button from '@glise/components/common/Button/Button';
import dayjs from 'dayjs';
import FormInput from '@glise/components/common/FormInput/FormInput';
import Container from '@mui/material/Container';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { API_URL } from '@glise/api/helpers';
import Toast from '@glise/components/common/Toast/Toast';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import LinkedInIcon from '@glise/icons/LinkedInIcon';
import TwitterIcon from '@glise/icons/TwitterIcon';
import useTranslation from 'next-translate/useTranslation';

export type SubscribeFormValues = {
  email: string;
};

interface FooterProps {
  setShowCookiesModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: FC<FooterProps> = ({ setShowCookiesModal }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm<SubscribeFormValues>();
  const { t } = useTranslation('footer');

  const subscribe = handleSubmit(async (data) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    setIsProcessing(true);

    try {
      // Obtain the reCAPTCHA token
      const token = await executeRecaptcha('subscribe');

      // Make the Axios request with the token
      const response = await axios.post(`${API_URL}api/v1/email/subscribe`, {
        email: data.email,
        ['g-recaptcha-response']: token,
      });

      console.log(response);

      // Handle successful subscription
      Toast({
        type: 'success',
        label: t('common:notifications.success'),
        message: t('common:notifications.subscribed'),
      });

      reset();
    } catch (error: any) {
      console.log(error);
      // Handle errors
      Toast({
        type: 'error',
        label: t('common:notifications.error'),
        message:
          error?.response?.data?.message ||
          t('common:notifications.subscribeFailed'),
      });
    } finally {
      setIsProcessing(false);
    }
  });

  return (
    <Container>
      <div className={styles.FooterContainer}>
        <div className={styles.FooterWrapper}>
          <div className={styles.LinksContainer}>
            <div className={styles.LinksWrapper}>
              <Link href="/" className={styles.Logo} aria-label={'Glise logo'}>
                <FullLogoIcon />
              </Link>
              <div className={styles.AllLinksContainer}>
                <div className={styles.LinksRow}>
                  <Link href="/pricing" aria-label={t('nav.pricing')}>
                    {t('nav.pricing')}
                  </Link>
                  <Link href="/contact" aria-label={t('nav.contact')}>
                    {t('nav.contact')}
                  </Link>
                </div>
                <div className={styles.SocialNetworks}>
                  <a
                    href="https://www.linkedin.com/company/glise-io"
                    target={'_blank'}
                    aria-label={'glise linkedin'}
                  >
                    <LinkedInIcon />
                  </a>
                  <a
                    href="https://x.com/glise_io"
                    target={'_blank'}
                    aria-label={'glise twitter'}
                  >
                    <TwitterIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <form className={styles.InputContainer} onSubmit={subscribe}>
            <div className={styles.InputTitle}>{t('subscribeForm.title')}</div>
            <div
              className={cn(
                styles.InputRow,
                errors.email?.message && styles.Centered,
              )}
            >
              <FormInput
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t('subscribeForm.email.invalidEmail'),
                  },
                })}
                error={errors.email?.message}
                // errorText
                label={t('subscribeForm.email.label')}
                type="email"
                placeholder={t('subscribeForm.email.placeholder')}
              />

              <Button
                breakpoint="lg"
                color="blue"
                type={'submit'}
                disabled={isProcessing}
                aria-label={t('subscribeForm.button.title')}
              >
                {t('subscribeForm.button.title')}
              </Button>
            </div>
            <div className={styles.InputPolicy}>
              {t('subscribeForm.agreeToPolicy.title')}
              <Link
                href="/privacy-policy"
                aria-label={t('subscribeForm.agreeToPolicy.privacyPolicy')}
              >
                {t('subscribeForm.agreeToPolicy.privacyPolicy')}
              </Link>
            </div>
          </form>
        </div>
        <div className={styles.FooterInfoContainer}>
          <div className={styles.FooterInfoWrapper}>
            <Link
              href="/privacy-policy"
              aria-label={t('policies.privacyPolicy')}
            >
              {t('policies.privacyPolicy')}
            </Link>
            <Link href="/terms-and-conditions" aria-label={t('policies.terms')}>
              {t('policies.terms')}
            </Link>
            <div onClick={() => setShowCookiesModal(true)}>
              {t('policies.cookies')}
            </div>
          </div>
          <div className={styles.Copyright}>
            © {dayjs().year().toString()} Glise. {t('copyright')}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
